import React from 'react'
// import Avatar from '@components/avatar'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from 'reactstrap'
import {Image} from 'react-bootstrap'
import { MoreVertical, Trash2 } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import  axios from '../../api/axios'


function NewsTable({data}) {
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const navigate = useNavigate()
  return (
    <Table className='table table-borderless'>
        <thead>
          <tr>
            <th className='th-id'>#</th>
            <th className='text-center'>Image</th>
            <th className='text-end'>Title</th>
            <th className='text-center'>Department</th>
            <th className='text-center'>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
            {data.map((fetchedNew, index) => {
                return (
                    <tr key={fetchedNew.newsId}>
                    <td>{index}</td>
                    <td> <Image src={`https://ps-node-prod.psct.gov.ly/${fetchedNew.image}`}/></td>
                    <td  className='text-muted col-4'>
                    <div className='row'>
                        <div dir='rtl'>{fetchedNew.title}</div>
                    </div>
                    </td>
   
                    <td className='text-center' dir='rtl'>{fetchedNew.department === null ? 'None': fetchedNew.department}</td>
    
                    <td className='text-muted text-center'>{fetchedNew.createdAt.split('T')[0]}</td>
                    <td>
                    <div className='column-action'>
                    <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                        <MoreVertical size={20} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                        tag='a'
                        href='/'
                        className='w-100'
                        onClick={ async (e) => {
                            e.preventDefault()
                            // delete user by newsId
                            try {
                              await axios.delete(`/news/${fetchedNew.newsId}`,{headers: { 
                                "content-type": "application/json",
                                "Authorization": `Bearer ${userData?.token}`
                              }})
                            window.location.reload()
                            } catch (error) {
                              if(error.response.data.message === 'jwt expired'){
                                navigate('/login')
                                window.localStorage.removeItem('userData')
                              }
                            }

                        }}
                        >
                        <Trash2 size={14} className='text-danger me-50' />
                        <span className='align-middle text-danger ms-1'>Delete</span>
                        </DropdownItem>
                    </DropdownMenu>
                    </UncontrolledDropdown>
                    </div>
                    </td>
                  </tr>
                )
            })}

      
        </tbody>
      </Table>
  )
}

export default NewsTable