import React, { useState, useEffect } from 'react'
import { CardBody, Row, Col, Input } from 'reactstrap'
import ReactPaginate from 'react-paginate'
import '../../styles/reports.scss'
import ReportsTable from './ReportsTable'
// import reports from '../../assets/dummy-data/reports'
import axios from '../../api/axios';

const Reports = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  // const navigate = useNavigate()
  // const [sidebarOpen, setSidebarOpen] = useState(false)
  // const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
  const [reports, setReports] = useState([])
  const [reportsPerPage, setReportsPerPage] = useState(10)
  const [pageCount,] = useState(Math.ceil(reports.length / reportsPerPage))
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const fetch = async () => {
      const response = await axios.get('/reports', {
        headers: {
          "content-type": "application/json",
          "Authorization": `Bearer ${userData?.token}`
        }
      })

      const reportsToRender = response.data.reports.rows.map((r) => {
        return {
          reportId: r.reportId,
          image: r.image,
          email: r.email,
          content: r.content,
          date: r.createdAt,
          status: r.resolved
        }
      })
      setReports(reportsToRender)
    }
    fetch()

  }, [userData?.token])

  const handlePageClick = async (data) => {
    setCurrentPage(data.selected + 1)
  }

  const indexOfLastReport = currentPage * reportsPerPage
  const indexOfFirstReport = indexOfLastReport - reportsPerPage
  const currentReports = reports.slice(indexOfFirstReport, indexOfLastReport)

  const handlePerPage = e => {
    const value = parseInt(e.currentTarget.value)
    setReportsPerPage(value)
  }
  return (
    <div className="card">
      <Row className='m-2'>
        <Col xl='6' className='d-flex align-items-center p-0'>
          <div className='d-flex align-items-center w-100 ms-4 mt-4'>
            <label htmlFor='rows-per-page'>Show</label>
            <Input
              className='mx-1'
              type='select'
              id='rows-per-page'
              // value={newsPerPage}
              onChange={handlePerPage}
              style={{ width: '5rem' }}
            >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
            </Input>
            <label htmlFor='rows-per-page'>Entries</label>
          </div>
        </Col>
      </Row>
      <Row className='m-4'>
        <Col >
          <h2>Reports List</h2>
        </Col>

      </Row>
      <CardBody>
        <ReportsTable data={currentReports} />

      </CardBody>

      <ReactPaginate
        previousLabel={'prev'}
        nextLabel={'next'}
        pageCount={pageCount || 1}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={page => handlePageClick(page)}
        pageClassName={'page-item'}
        nextLinkClassName={'page-link rounded-end'}
        nextClassName={'page-item '}
        previousClassName={'page-item '}
        previousLinkClassName={'page-link rounded-start'}
        pageLinkClassName={'page-link'}
        containerClassName={'pagination react-paginate justify-content-end my-3 mx-4 pe-1'}
      />
    </div>
  );
};

export default Reports;
