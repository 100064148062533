import React from "react";
import { Icon } from '@iconify/react';

const SingleCard = (props) => {
  const { title, totalNumber, icon , bgColor } = props.item;
  return (
    <div className={`single__card ${bgColor}`}>
      <div className="card__content">
        <h3>{title}</h3>
        <span>{totalNumber}</span>
      </div>

      <span className="card__icon">
        <Icon icon={icon} width="40" height="40" />
      </span>
    </div>
  );
};

export default SingleCard;
