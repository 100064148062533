import React from "react";
import Router from "./routes/Router";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.scss";


function App() {
  return (
    <Router/>
  );
}

export default App;
