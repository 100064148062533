import React from "react";

// import { Link } from "react-router-dom";
import profileImg from "../../assets/images/profile-06.png";
import "./top-nav.css";


const TopNav = () => {

  const userData = JSON.parse(window.localStorage.getItem('userData'))

  return (
    <div className="top__nav">
      <div className="top__nav-wrapper">
        <div className="profile d-flex align-items-center">
            <span className="text-light me-2">{ userData.userName.charAt(0).toUpperCase() + userData.userName.slice(1)}</span>
            <img src={profileImg} alt="" />

        </div>
      </div>
    </div>
  );
};

export default TopNav;
