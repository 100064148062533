// ** React Import
// import { useState } from 'react'

// ** Custom Components
import SidebarForm from '../../components/SidebarForm'
import axios from '../../api/axios';
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useState, useEffect } from 'react'

// ** Reactstrap Imports
import { Button, Label, Form, Input } from 'reactstrap'


const defaultValues = {
  areaName: '',
  lng: 32,
  lat: 13,
  number: 1,
  municipalityId: 'cc68f631-9ed4-4b87-a611-631fb354ffb8',
}


const schema = yup.object({
  areaName: yup.string().required().matches(/^[\u0621-\u064A ]+$/, 'Please enter a valid arabic text'),
  lng: yup.number().required('Please enter a valid longitude'),
  lat: yup.number().required('Please enter a valid latitude'),
  number: yup.number().required('Please enter a valid number of containers'),
  municipalityId: yup.string().required().matches(/^[A-Za-z0-9-]*$/, 'Please enter a valid municipality id'),
})

const SidebarAddContainer = ({ open, toggleSidebar }) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const navigate = useNavigate()

  const [municipalities, setMunicipalities] = useState([])
  const [formVisible, setFormVisible] = useState(true);

  useEffect(() => {
    if (formVisible) {
      const fetch = async () => {
        const response = await axios.get('/municipality', {
          headers: {
            "content-type": "application/json",
            "Authorization": `Bearer ${userData?.token}`
          }
        })

        setMunicipalities(response.data.municipalities)
      }
      fetch()
    }

  }, [userData?.token, formVisible])

  // ** Vars
  const {
    control,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues, resolver: yupResolver(schema) })

  // ** Function to handle form submit
  const onSubmit = async data => {
    const formData = new FormData()

    formData.append('areaName', data.areaName)
    formData.append('location', JSON.stringify({
      lng: data.lng,
      lat: data.lat
    }));
    formData.append('number', data.number)
    formData.append('municipalityId', data.municipalityId)
    toggleSidebar()
    // add container
    try {
      await axios.post('/containers', formData, {
        headers: {
          "Authorization": `Bearer ${userData?.token}`
        }
      })
      window.location.reload()
    } catch (error) {
      if (error.response.data.message === 'jwt expired') {
        navigate('/login')
        window.localStorage.removeItem('userData')
      }

    }
  }



  const handleSidebarClosed = () => {
    for (const key in defaultValues) {
      setValue(key, '')
    }
    setFormVisible(false)
    clearErrors(['areaName', 'lng', 'lat', 'number', 'municipalityId'])
  }

  return (
    <SidebarForm
      size='lg'
      open={open}
      title='New User'
      headerClassName='mb-1'
      contentClassName='pt-0'
      toggleSidebar={toggleSidebar}
      onClosed={handleSidebarClosed}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-1'>
          <Label className='form-label' for='areaName'>
            AreaName <span className='text-danger'>*</span>
          </Label>
          <small className=' text-danger'>{errors.areaName?.message}</small>
          <Controller
            name='areaName'
            control={control}
            render={({ field }) => (
              <Input id='areaName' placeholder='johnDoe99' invalid={errors.areaName && true} {...field} />
            )}
          />
          <Label className='form-label' for='lng'>
            Longitude <span className='text-danger'>*</span>
          </Label>
          <small className=' text-danger'>{errors.lng?.message}</small>
          <Controller
            name='lng'
            control={control}
            render={({ field }) => (
              <Input id='lng' placeholder='longitude' invalid={errors.lng && true} {...field} />
            )}
          />
          <Label className='form-label' for='lat'>
            Latitude <span className='text-danger'>*</span>
          </Label>
          <small className=' text-danger'>{errors.lat?.message}</small>
          <Controller
            name='lat'
            control={control}
            render={({ field }) => (
              <Input id='lat' placeholder='latitude' invalid={errors.lat && true} {...field} />
            )}
          />
          <Label className='form-label' for='number'>
            Number <span className='text-danger'>*</span>
          </Label>
          <small className=' text-danger'>{errors.number?.message}</small>
          <Controller
            name='number'
            control={control}
            render={({ field }) => (
              <Input id='number' placeholder='number of containers' invalid={errors.number && true} {...field} />
            )}
          />
          <Label className='form-label' for='municipality'>
            Municipality
          </Label>
          <small className=' text-danger'>{errors.municipalityId?.message}</small>
          <Controller
            name='municipalityId'
            control={control}
            render={({ field }) => (
              <Input type='select' id='municipalityId' invalid={errors.municipality && true}  {...field}
                onChange={e => {
              
                  setValue('municipalityId', e.target.value);
                }}>
                {municipalities.map((option) => (
                  <option key={option.municipalityId} value={option.municipalityId}>
                    {option.municipalityName}
                  </option>
                ))}
              </Input>
            )}
          />
        </div>

        <div className='mt-4'>
          <Button type='submit' className='me-1' color='primary'>
            Submit
          </Button>
          <Button type='reset' color='secondary' outline onClick={toggleSidebar}>
            Cancel
          </Button>
        </div>
      </Form>
    </SidebarForm>
  )
}

export default SidebarAddContainer
