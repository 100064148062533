import React from 'react'
import './contentWrapper.css'

function ContentWrapper(props) {
  return (
    <div className='main-content-wrapper'>
        {props.children}
    </div>
  )
}

export default ContentWrapper