import SidebarForm from '../../components/SidebarForm'
import { useForm, } from 'react-hook-form'
import { Button, Label, Form } from 'reactstrap'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from '../../api/axios';
import { useNavigate } from 'react-router-dom';


const defaultValues = {
  title: '',
  newImage: '',
  department:'عامة'
}

const schema = yup.object({
  title: yup.string().min(5).required().matches(/^[\u0621-\u064A0-9 ]+$/, "يجب ان يحتوي على حروف عربية وأرقام فقط"),
  newImage: yup
              .mixed()
              .required("You need to provide a file")
              .test("type", "Only the following formats are accepted: .jpeg, .jpg, .png, .webp", (value) => {
                  return value && (
                      value[0].type === "image/jpeg" ||
                      value[0].type === "image/webp" ||
                      value[0].type === "image/png" 
                  );
              }).required(),
  department: yup.string()
})


const SidebarAddNew = ({ open, toggleSidebar }) => {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  });

  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const navigate = useNavigate()

  // ** Function to handle form submit
  const onSubmit = async data => {
    const formData = new FormData()
    formData.append('title',data.title)
    formData.append('image',data.newImage[0])
    formData.append('department',data.department)
    toggleSidebar()
    // add news
    try {
      await axios.post('/news',formData,{headers: { 
        "Authorization": `Bearer ${userData?.token}`
      }})
      window.location.reload()
    } catch (error) {
      if(error.response.data.message === 'jwt expired'){
        navigate('/login')
        window.localStorage.removeItem('userData')
      }
    }
  }

  const handleSidebarClosed = () => {
    for (const key in defaultValues) {
      setValue(key, '')
    }
    setValue('department','عامة')
  }

  return (
    <SidebarForm
      size='lg'
      open={open}
      title='Add New'
      headerClassName='mb-1'
      contentClassName='pt-0'
      toggleSidebar={toggleSidebar}
      onClosed={handleSidebarClosed}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-1'>
          <Label className='form-label' for='title'>
            Title <span className='text-danger'>*</span>
          </Label>
          <input id='title' className='form-control' placeholder='add title' {...register('title')} />
          <small className=' text-danger'>{errors.title?.message}</small>

        </div>
        <div className='mb-1'>
          <Label className='form-label' for='newImage'>
            New Image <span className='text-danger'>*</span>
          </Label>
          <input className='form-control'
                type='file'
                id='newImage'
                {...register("newImage")}
              />
              <small className=' text-danger'>{errors.newImage?.message}</small>
        </div>
        <div className='mb-1'>
          <Label className='form-label' for='department'>
            Department
          </Label>
          <select id="department" className='form-control'
            {...register('department')}>
            <option value='عامة'>عامة</option>
            <option value='إدارة الحركة والنقل'>إدارة الحركة والنقل</option>
            <option value='إدارة الاحتفالات والزينة'>إدارة الاحتفالات والزينة</option>
            <option value='إدارة الإنتاج'>إدارة الإنتاج</option>
            <option value='إدارة الحدائق'>إدراة الحدائق</option>
            <option value='إدارة الورش'>إدارة الورش</option>
            <option value='إدارة المشروعات'>إدارة المشروعات</option>
            <option value='إدارة الوقاية الصحية'>إدارة الوقاية الصحية</option>
            <option value='إدارة تقنية المعلومات'>إدارة تقنية المعلومات</option>
            <option value='إدارة الشؤون الإدارية'>إدارة الشؤون الإدارية</option>
            <option value='إدارة الشؤو المالية'>إدارة الشؤون المالية</option>
            <option value='إدارة الطوارئ'>إدارة الطوارئ</option>
            <option value='إدارة المكبات'>إدارة المكبات</option>
            <option value='إدارة النظافة'>إدارة النظافة</option>
          </select>
          <small className=' text-danger'>{errors.department?.message}</small>

        </div>


      <div className='mt-4'>
        <Button type='submit' className='me-1' color='primary'>
          Submit
        </Button>
        <Button type='reset' color='secondary' outline onClick={toggleSidebar}>
          Cancel
        </Button>
        </div>
      </Form>
    </SidebarForm>
  )
}

export default SidebarAddNew
