import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Dashboard from "../pages/dashboard/Dashboard.jsx";
import NotFound from "../pages/notfound/NotFound.jsx";
import Reports from "../pages/reports/Reports.jsx";
import Users from "../pages/users/Users.jsx";
import News from "../pages/news/News.jsx";
import Containers from "../pages/containers/Containers.jsx";
import Login from "../pages/login/Login";
import Blank from "../components/Layout/Blank.jsx";
import Layout from "../components/Layout/Layout.jsx";


const Router = () => {
  const userData = window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData')) : null

  return (
    <Routes>
      <Route element={<Blank/>}>
      <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound/>} />
      </Route>
      <Route element={<Layout/>}>
        {
          userData?.superUser ? (
            <>
            <Route
            path="/"
            element={<Navigate to="/dashboard" element={<Dashboard />} />}
          />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/news" element={<News />} />
            <Route path="/containers" element={<Containers />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/users" element={<Users />} />
            </>
          ) :(
            <>
            <Route
            path="/"
            element={<Navigate to="/dashboard" element={<Dashboard />} />}
          />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/news" element={<News />} />
          <Route path="/containers" element={<Containers />} />
          <Route path="/reports" element={<Reports />} />
          </>
          )
        }
      </Route>

    </Routes>
  );
};

export default Router;
