import React from 'react'
// import Avatar from '@components/avatar'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from 'reactstrap'

import { MoreVertical, Trash2 } from 'react-feather'
// import { Link } from 'react-router-dom'
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';

function NewsTable({ data }) {
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const navigate = useNavigate()
  return (
    <Table className='table table-borderless'>
      <thead>
        <tr>
          <th className='th-id'>#</th>
          <th className='text-center'>Place Name</th>
          <th className=''>Latitude</th>
          <th className=''>Longitude</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((fetchedContainer, index) => {
          return (
            <tr key={index}>
              <td >{index + 1}</td>
              <td dir='rtl' className='text-center'>{fetchedContainer.placeName}</td>
              <td className='text-muted '>{fetchedContainer.location.lat}</td>
              <td className='text-muted '>{fetchedContainer.location.lng}</td>
              <td>
                <div className='column-action'>
                  <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                      <MoreVertical size={20} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        tag='a'
                        href='/'
                        className='w-100'
                        onClick={async (e) => {
                          e.preventDefault()
                          // delete container
     //TODO: this sometimes prints nothing depending on where you click on with cursor!
                          try {
                            await axios.delete(`/containers/${fetchedContainer.containerId}`, {
                              headers: {

                                "Authorization": `Bearer ${userData?.token}`
                              }
                            })
                            window.location.reload()
                          } catch (error) {
          
                            if (error.response.data.message === 'jwt expired') {
                              navigate('/login')
                              window.localStorage.removeItem('userData')
                            }
                          }
                        }}
                      >
                        <Trash2 size={14} className='text-danger me-50' />
                        <span id={fetchedContainer.containerId} className='align-middle text-danger ms-1'>Delete</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </td>
            </tr>
          )
        })}


      </tbody>
    </Table>
  )
}

export default NewsTable