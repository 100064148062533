import React,{useState,useEffect} from 'react'
import { CardBody, Row, Col, Button, Input} from 'reactstrap'
import ReactPaginate from 'react-paginate'
import '../../styles/users.scss'
import UsersTable from './UsersTable'
// import users from '../../assets/dummy-data/users'
// import Sidebar from './SidebarNewUsers'
import SidebarNewUsers from './SidebarNewUsers'
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom'

const Users = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const navigate = useNavigate()

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
  const [users, setUsers] = useState([])
  const [usersPerPage, setUsersPerPage] = useState(10)
  const [pageCount,setPageCount ] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(()=>{
    const getUsers = async ()=>{
      // get all user
      try {
        const response = await axios.get('/users',{headers:{ 
          "content-type": "application/json",
          "Authorization": `Bearer ${userData?.token}`
        }})
        setUsers(response.data.users.rows)
        setPageCount(Math.ceil(response.data.users.rows.length/usersPerPage))
      } catch (error) {
        if(error.response.data.message === 'jwt expired'){
          navigate('/login')
          window.localStorage.removeItem('userData')
        }
      }


    }
    getUsers()
  },[usersPerPage,userData?.token,navigate])

  const handlePageClick = async (data) => {
    setCurrentPage(data.selected + 1)
  }

  const indexOfLastUser = currentPage * usersPerPage
  const indexOfFirstUser = indexOfLastUser - usersPerPage
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser)

  const handlePerPage = e => {
    const value = parseInt(e.currentTarget.value)
    setUsersPerPage(value)
  }
  return (
    <div className="card">
      <Row className='m-2'>
          <Col xl='6' className='d-flex align-items-center p-0'>
          <div className='d-flex align-items-center w-100 ms-4 mt-4'>
            <label htmlFor='rows-per-page'>Show</label>
            <Input
              className='mx-1'
              type='select'
              id='rows-per-page'
              onChange={handlePerPage}
              style={{ width: '5rem' }}
            >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
            </Input>
            <label htmlFor='rows-per-page'>Entries</label>
          </div>
        </Col>
          </Row>
          <Row className='m-4'>
            <Col >
            <h2>Users List</h2>
            </Col>
            <Col className='text-end'>
            <Button onClick={toggleSidebar}>Add User</Button>
            </Col>
          </Row>
          <CardBody>
          <UsersTable data={currentUsers}/>
          
          </CardBody>

          <ReactPaginate
        previousLabel={'prev'}
        nextLabel={'next'}
        pageCount={pageCount || 1}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={page => handlePageClick(page)}
        pageClassName={'page-item'}
        nextLinkClassName={'page-link rounded-end'}
        nextClassName={'page-item '}
        previousClassName={'page-item '}
        previousLinkClassName={'page-link rounded-start'}
        pageLinkClassName={'page-link'}
        containerClassName={'pagination react-paginate justify-content-end my-3 mx-4 pe-1'}
      />
      <SidebarNewUsers open={sidebarOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default Users;
