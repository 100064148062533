import React, {useState} from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from 'reactstrap'
import { MoreVertical, Trash2, Archive , ToggleRight,ToggleLeft } from 'react-feather'
import axios from '../../api/axios'
// import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import SidebarEditUsers from './SidebarEditUsers'


function UsersTable({data}) {
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const navigate = useNavigate()
  const [editSidebarOpen, setEditSidebarOpen] = useState(false)
  const toggleEditSidebar = () => setEditSidebarOpen(!editSidebarOpen)
  const [editUser,setEditUser] = useState(null)

  return (
    <Table className='table table-borderless'>
        <thead>
          <tr>
            <th>User</th>
            <th>Role</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
            {data.map((user, index) => {
               if (userData.userId === user.userId){
                return null
               }
                return (
                    <tr key={index}>
                    <th className='text-muted' scope="row" >{user.userName}</th>
                    <td className='text-muted'>{user.superUser ? 'Admin':'Author'}</td>
                    <td>{user.is_active === true ? (
                       <span className='text-light bg-success rounded-pill p-2 fw-bold'>active</span>
                    ) : (
                    <span className='text-light bg-danger rounded-pill p-2 fw-bold'>inactive</span>
                    )}
                    </td>
                    <td>
                    <div className='column-action'>
                    <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                        <MoreVertical size={18} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem tag='a' href='/' className='w-100' onClick={async (e) => {
                          e.preventDefault()
                          setEditUser(null)
                          await setEditUser(user)
                          toggleEditSidebar()
                          return 
                        }}>
                        <Archive size={14} className='text-primary me-50' />
                        <span className='align-middle text-primary ms-1'>Edit User</span>
                        </DropdownItem>





                        <DropdownItem tag='a' href='/' className='w-100' onClick={e => e.preventDefault()}>
                          <>
                          { user.superUser === true ? 
                          (<div onClick={async (e) => {
                            e.preventDefault()
                            // update user status
                            const formData = new FormData()
                            formData.append('superUser',false)
                            try {
                              await axios.patch(`/users/superuserstatus/${user.userId}`,formData,{headers:{ 
                                
                                "Authorization": `Bearer ${userData?.token}`
                              }})
                              window.location.reload()
                            } catch (error) {
         
                              if(error.response.data.message === 'jwt expired'){
                                navigate('/login')
                                window.localStorage.removeItem('userData')
                              }
                            }
                        }}>
                              <ToggleRight size={14} className='text-success me-50'/>
                              <span className='align-middle text-success ms-1'>Admin</span>
                            </div>)
                          :
                          (<div onClick={async (e) => {
                            e.preventDefault()
                            const formData = new FormData()
                            formData.append('superUser',true)
                            // update user status
                            try {
                              await axios.patch(`/users/superuserstatus/${user.userId}`,formData,{headers:{ 
                                
                                "Authorization": `Bearer ${userData?.token}`
                              }})
                              window.location.reload()
                            } catch (error) {
  
                              if(error.response.data.message === 'jwt expired'){
                                navigate('/login')
                                window.localStorage.removeItem('userData')
                              }
                            }
      
                            
                        }}>
                              <ToggleLeft size={14} className='text-secondary me-50'/>
                              <span className='align-middle text-secondary ms-1'>Admin</span>
                            </div>)
                          }
                          </>
                        
                        </DropdownItem>
                        <DropdownItem tag='a' href='/' className='w-100' onClick={e => e.preventDefault()}>
                          <>
                          { user.is_active === true ? 
                          (<div onClick={async (e) => {
                            e.preventDefault()
                            // update user status
                            const formData = new FormData()
                            formData.append('is_active',false)
                            try {
                              await axios.patch(`/users/status/${user.userId}`,formData,{headers:{ 
                                
                                "Authorization": `Bearer ${userData?.token}`
                              }})
                              window.location.reload()
                            } catch (error) {
    
                              if(error.response.data.message === 'jwt expired'){
                                navigate('/login')
                                window.localStorage.removeItem('userData')
                              }
                            }
                        }}>
                              <ToggleRight size={14} className='text-success me-50'/>
                              <span className='align-middle text-success ms-1'>Status</span>
                            </div>)
                          :
                          (<div onClick={async (e) => {
                            e.preventDefault()
                            const formData = new FormData()
                            formData.append('is_active',true)
                            // update user status
                            try {
                              await axios.patch(`/users/status/${user.userId}`,formData,{headers:{ 
                                
                                "Authorization": `Bearer ${userData?.token}`
                              }})
                              window.location.reload()
                            } catch (error) {
      
                              if(error.response.data.message === 'jwt expired'){
                                navigate('/login')
                                window.localStorage.removeItem('userData')
                              }
                            }
      
                            
                        }}>
                              <ToggleLeft size={14} className='text-secondary me-50'/>
                              <span className='align-middle text-secondary ms-1'>Status</span>
                            </div>)
                          }
                          </>
                        
                        </DropdownItem>
                        <DropdownItem
                        tag='a'
                        href='/'
                        className='w-100'
                        onClick={async (e) => {
                            e.preventDefault()
                            // delete user
                            try {
                              await axios.delete(`/users/${user.userId}`,{headers:{ 
                                "content-type": "application/json",
                                "Authorization": `Bearer ${userData?.token}`
                              }})
                              window.location.reload()
                            } catch (error) {
                              if(error.response.data.message === 'jwt expired'){
                                navigate('/login')
                                window.localStorage.removeItem('userData')
                              }
                            }
      
                        }}
                        >
                        <Trash2 size={14} className='text-danger me-50' />
                        <span className='align-middle text-danger ms-1'>Delete</span>
                        </DropdownItem>
                    </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                    </td>
                  </tr> 
                )
            })}

        </tbody>
        {editUser && <SidebarEditUsers open={editSidebarOpen} toggleSidebar={toggleEditSidebar} editUser={editUser} setEditUser={setEditUser} /> }
        
      </Table>
  )
}

export default UsersTable

