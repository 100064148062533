import React from "react";
import { NavLink,useNavigate } from "react-router-dom";
import navLinks from "./navLinks";
import "./sidebar.css";
import { Icon } from '@iconify/react';


const Sidebar = () => {
  const userData = window.localStorage.getItem('userData') ? JSON.parse(window.localStorage.getItem('userData')) : null
  const navigate = useNavigate()
  return (
    <div className="sidebar">
      <div className="sidebar__top mt-2">
        <h2>
          <span>
            {/* <i class="ri-taxi-line"></i> */}
            <img  src={require ( '../../assets/images/logo.webp')} alt="logo" />
          </span>{" "}
          Admin
        </h2>
      </div>
      <div className="sidebar__content">
        <div className="menu">
          <ul className="nav__list">
            {
              userData.superUser? (<>{
                navLinks.map((item, index) => (
                  <li className="nav__item" key={index}>
                    <NavLink 
                      to={item.path}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active nav__link" : "nav__link"
                      }
                    >
                      <Icon className="link-icon" width="20" height="20" icon={item.icon} />
                      {item.display}
                    </NavLink>
                  </li>
                ))
              }</>) : (<>{
                navLinks.map((item, index) => (
                  <div key={index}>
                  { item.path !== '/users' ?(
                      <li className="nav__item" key={index}>
                        <NavLink 
                          to={item.path}
                          className={(navClass) =>
                            navClass.isActive ? "nav__active nav__link" : "nav__link"
                          }
                        >
                          
                          <Icon className="link-icon" width="20" height="20" icon={item.icon} />
        
                          {item.display}
                        </NavLink>
                        </li>
                      ): null
                    }
                 </div>
                  
                ))
              }</>)

            }
          </ul>
        </div>

        <div className="sidebar__bottom" onClick={()=>{
          navigate('/login')
          window.localStorage.removeItem('userData')
        }}>
          <span>
            <i className="ri-logout-circle-r-line"></i> Logout
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
