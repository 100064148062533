import React from "react";
// import Router from "../../routes/Router";
import Sidebar from "../Sidebar/Sidebar";
import TopNav from "../TopNav/TopNav";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import { Navigate, Outlet, useLocation } from "react-router-dom";


const Layout = () => {

  const location = useLocation()
  const userData = JSON.parse(window.localStorage.getItem('userData'))

  return (
    userData ? (
      <div className="layout">
      <Sidebar />
      <div className="main__layout">
        <TopNav />
        <ContentWrapper>
          <Outlet/>
        </ContentWrapper>
      </div>
    </div>
    ): <Navigate to='/login' state={{from: location}} replace />
  );
};

export default Layout;
