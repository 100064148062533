import React from "react";
import { Outlet } from "react-router-dom";



const Blank = () => {
  return (
    <div className="Blank">
         <Outlet/>       
    </div>
  );
};

export default Blank;