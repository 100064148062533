import React from "react";
import "../../styles/dashboard.scss";
import SingleCard from "../../components/reuseable/SingleCard";
import users from '../../assets/dummy-data/users'
import axios from '../../api/axios';
import { useState, useEffect } from 'react'


const ReportsObj = {
  title: "Total Reports",
  totalNumber: 0,
  icon: "akar-icons:bug",
  bgColor: 'ReportsObj'
};

const NewsObj = {
  title: "Total News",
  totalNumber: 0,
  icon: "emojione-monotone:newspaper",
  bgColor: 'NewsObj'
};

const ContainersObj = {
  title: "Total Containers",
  totalNumber: 0,
  icon: "octicon:container-24",
  bgColor: 'ContainersObj'
};

const UsersObj = {
  title: "Total Users",
  totalNumber: users.length,
  icon: "heroicons:users",
  bgColor: 'UsersObj'
};

const Dashboard = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const [userObj,setUserObj] = useState(UsersObj)
  const [containersObj,setContainersObj] = useState(ContainersObj)
  const [newsObj,setNewsObj] = useState(NewsObj)
  const [reportsObj,setReportsObj] = useState(ReportsObj)

  // useEffect(() => {
  //   const fetch = async () => {

  //     var response1 = await axios.get('/news')
  //     setNewsObj({...newsObj, totalNumber: response1.data.news.rows.length})

  //     var response2 = await axios.get('/reports', {
  //       headers: {
  //         "content-type": "application/json",
  //         "Authorization": `Bearer ${userData?.token}`
  //       }
  //     })
  //     setReportsObj({...reportsObj, totalNumber: response2.data.reports.rows.length })

  //     var response3 = await axios.get('/users', {
  //       headers: {
  //         "content-type": "application/json",
  //         "Authorization": `Bearer ${userData?.token}`
  //       }
  //     })
  //     setUserObj({...userObj, totalNumber: response3.data.users.rows.length })

  //     var response4 = await axios.get('/containers')
  //     setContainersObj({...containersObj, totalNumber: response4.data.containers.length })
 
  //   }
  //   fetch()
  // },[userData?.token, userObj,reportsObj,newsObj,containersObj])
  
  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="row">
          <div className="col  col-md-6 col-lg-6 col-xl-6 mt-4">
            <SingleCard item={3} />
            {/* <SingleCard item={reportsObj} /> */}
          </div>
          <div className="col  col-md-6 col-lg-6 col-xl-6 mt-4">
            <SingleCard item={3} />
            {/* <SingleCard item={newsObj} /> */}
          </div>
        </div>
        <div className="row">
            <div className="col  col-md-6 col-lg-6 col-xl-6 mt-4">
              <SingleCard item={3} />
              {/* <SingleCard item={containersObj} /> */}
            </div>
            <div className="col  col-md-6 col-lg-6 col-xl-6 mt-4">
              <SingleCard item={3} />
              {/* <SingleCard item={userObj} /> */}
            </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
