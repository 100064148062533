import React, { useState, useEffect } from 'react'
import { CardBody, Row, Col, Button, Input } from 'reactstrap'
import ReactPaginate from 'react-paginate'
import '../../styles/containers.scss'
import ContainersTable from './ContainersTable'
// import containers from '../../assets/dummy-data/containers'
import SidebarAddContainer from './SidebarAddContainer'
import axios from '../../api/axios';

const Containers = () => {


  const [sidebarOpen, setSidebarOpen] = useState(false)
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
  const [containers, setcontainers] = useState([])
  const [containersPerPage, setContainersPerPage] = useState(10)
  const [pageCount,] = useState(Math.ceil(containers.length / containersPerPage))
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    const fetch = async () => {
      const response = await axios.get('/containers', {
        headers: {
          "content-type": "application/json",
        }
      })
      const containersToRender = response.data.containers.map((c) => {
        return {
          containerId: c.containerId,
          placeName: c.areaName,
          location: {
            lat: c.lat,
            lng: c.lng
          }
        }
      })
      setcontainers(containersToRender)
    }
    fetch()

  }, [])

  const handlePageClick = async (data) => {
    setCurrentPage(data.selected + 1)
  }

  const indexOfLastContainer = currentPage * containersPerPage
  const indexOfFirstContainer = indexOfLastContainer - containersPerPage
  const currentContainers = containers.slice(indexOfFirstContainer, indexOfLastContainer)

  const handlePerPage = e => {
    const value = parseInt(e.currentTarget.value)
    setContainersPerPage(value)
  }
  return (
    <div className="card">
      <Row className='m-2'>
        <Col xl='6' className='d-flex align-items-center p-0'>
          <div className='d-flex align-items-center w-100 ms-4 mt-4'>
            <label htmlFor='rows-per-page'>Show</label>
            <Input
              className='mx-1'
              type='select'
              id='rows-per-page'
              // value={newsPerPage}
              onChange={handlePerPage}
              style={{ width: '5rem' }}
            >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
            </Input>
            <label htmlFor='rows-per-page'>Entries</label>
          </div>
        </Col>
      </Row>
      <Row className='m-4'>
        <Col >
          <h2>Containers List</h2>
        </Col>
        <Col className='text-end'>
          <Button onClick={toggleSidebar}>Add Container</Button>
        </Col>
      </Row>
      <CardBody>
        <ContainersTable data={currentContainers} />

      </CardBody>

      <ReactPaginate
        previousLabel={'prev'}
        nextLabel={'next'}
        pageCount={pageCount || 1}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={page => handlePageClick(page)}
        pageClassName={'page-item'}
        nextLinkClassName={'page-link rounded-end'}
        nextClassName={'page-item '}
        previousClassName={'page-item '}
        previousLinkClassName={'page-link rounded-start'}
        pageLinkClassName={'page-link'}
        containerClassName={'pagination react-paginate justify-content-end my-3 mx-4 pe-1'}
      />
      <SidebarAddContainer open={sidebarOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default Containers;
