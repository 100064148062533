import React,{useState, useEffect} from 'react'
import { CardBody, Row, Col, Button} from 'reactstrap'
import ReactPaginate from 'react-paginate'
import '../../styles/news.scss'
import NewsTable from './NewsTable'

import SidebarAddNew from './SidebarAddNew'
import axios from '../../api/axios';

// import useRefreshToken from '../../hooks/useRefreshToken'

const News = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
  const [news, setNews] = useState([])
  const [newsPerPage,] = useState(6)
  const [pageCount,setPageCount ] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(()=>{
    const fetch = async ()=>{
      const response = await axios.get('/news')
      setNews(response.data.news.rows)
      setPageCount(Math.ceil(parseInt(response.data.news.count)/newsPerPage))
    }
    fetch()
  },[newsPerPage])

  const handlePageClick = async (data) => {
    setCurrentPage(data.selected + 1)
    let currPage = data.selected + 1;
    const res = await fetchPage(currPage);
    setNews(res.news.rows);

  }

  const fetchPage = async (currentPage) => {

    const res = await axios.get(
      `/news?page=${currentPage}`
    );
    return res.data;
  };

  return (
    <div className="card">
          <Row className='m-4'>
            <Col >
            <h2>News List</h2>
            </Col>
            <Col className='text-end'>
            <Button onClick={toggleSidebar} >Add New</Button>
            </Col>
          </Row>
          <CardBody>
          <NewsTable data={news}/>
          </CardBody>
          <ReactPaginate
        previousLabel={'prev'}
        nextLabel={'next'}
        pageCount={pageCount || 1}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={page => handlePageClick(page)}
        pageClassName={'page-item'}
        nextLinkClassName={'page-link rounded-end'}
        nextClassName={'page-item '}
        previousClassName={'page-item '}
        previousLinkClassName={'page-link rounded-start'}
        pageLinkClassName={'page-link'}
        containerClassName={'pagination react-paginate justify-content-end my-3 mx-4 pe-1'}
      />
      <SidebarAddNew open={sidebarOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default News;
