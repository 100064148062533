// ** React Import
import SidebarForm from '../../components/SidebarForm'
import { useForm} from 'react-hook-form'
import { Button, Label,  Form } from 'reactstrap'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';




const schema = yup.object({
  userName: yup.string(),
  email: yup.string().email(),
  role: yup.string(),
  password1: yup.string().min(4).max(20),
  password2: yup.string().oneOf([yup.ref('password1'),null],"passwords don't matchs"),
})

const SidebarEditUsers = ({ open, toggleSidebar,editUser,setEditUser }) => {
    const defaultValues = {
      userName: editUser.userName ,
      email: editUser.email,
      // role:editUser.role,
      password1:'',
      password2:''
    }

  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const navigate = useNavigate()

  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  });

  // ** Function to handle form submit
  const onSubmit = async data => {
    setEditUser(null)
    const formData = new FormData()
    formData.append('userId',editUser.userId)
    formData.append('userName',data.userName)
    formData.append('email',data.email)
    formData.append('password',data.password1)
    // formData.append('superUser',data.role === 'Author' ? false : true)
    toggleSidebar()
        // edit user
        //TODO: catch error messages and give it to user
      try {
        await axios.patch(`/users/${editUser.userId}`,formData,{headers:{

          "Authorization": `Bearer ${userData?.token}`
        }})
        window.location.reload()
      } catch (error) {
  
        if(error.response.data.message === 'jwt expired'){
          navigate('/login')
          window.localStorage.removeItem('userData')
        }
      }



  }

  const handleSidebarClosed = () => {
    for (const key in defaultValues) {
      setValue(key, '')
    }
    clearErrors(['userName','email','password1','password2','role'])
    setEditUser(null)
  }

  return (
    <SidebarForm
      size='lg'
      open={open}
      title='Edit User'
      headerClassName='mb-1'
      contentClassName='pt-0'
      toggleSidebar={toggleSidebar}
      onClosed={handleSidebarClosed}
    >
    
<Form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-1'>
          <Label className='form-label' for='userName'>
            Username
          </Label>
          <input 
          className='form-control'
          id='userName'
          type='text'
          name='userName'
          disabled={true}
          placeholder='enter username'
          invalid={errors.userName && 'true'}
          {...register('userName')}
          />
          <small className=' text-danger'>{errors.userName?.message}</small>
        </div>
        <div className='mb-1'>
          <Label className='form-label' for='userEmail'>
            Email 
          </Label>
          <input
                className='form-control'
                type='email'
                id='userEmail'
                name='email'
                invalid={errors.email && 'true'}
                {...register('email')}
              />
          {errors.email && <small className=' text-danger'>{errors.email?.message}</small> }
        </div>
        <div className='mb-1'>
          <Label className='form-label' for='password1'>
            Password 
          </Label>
          <input
                type='password'
                className='form-control'
                id='password1'
                name='password1'
                placeholder='Password'
                invalid={errors.password1 && 'true'}
                {...register('password1')}
              />
          <small className=' text-danger'>{errors.password1?.message}</small>
        </div>
        <div className='mb-1'>
          <Label className='form-label' for='password2'>
            Password 
          </Label>
          <input
                type='password'
                className='form-control'
                id='password2'
                name='password2'
                placeholder='Password'
                invalid={errors.password2 && 'true'}
                {...register('password2')}
              />
          <small className=' text-danger'>{errors.password2?.message}</small>
        </div>
        {/* <div className='mb-1'>
          <Label className='form-label' for='role'>
            User Role
          </Label>
          <select className='form-control' type='select' id='role' name='role' invalid={errors.role && true}  {...register('role')} >
                <option value='Author'>Author</option>
                <option value='Admin'>Admin</option>
              </select>
        </div> */}

        <div className='mt-4'>
        <Button type='submit' className='me-1' color='primary'>
          Submit
        </Button>
        <Button type='reset' color='secondary' outline onClick={toggleSidebar}>
          Cancel
        </Button>
        </div>
      </Form>
    </SidebarForm>
  )
}

export default SidebarEditUsers
