const usersData = [
    {
        username:'moha',
        email:'moha@elbuni',
        role:'admin',
        is_active:false
    },
    {
        username:'moha',
        email:'moha@elbuni',
        role:'admin',
        is_active:true
    },
    {
        username:'moha',
        email:'moha@elbuni',
        role:'admin',
        is_active:true
    },
]

export default usersData