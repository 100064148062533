const navLinks = [
  {
    path: "/dashboard",
    icon: "ic:sharp-space-dashboard",
    display: "Dashboard",
  },
  {
    path: "/reports",
    icon: "ant-design:bug-filled",
    display: "Reports",
  },
  {
    path: "/containers",
    icon: "fluent:bin-full-20-filled",
    display: "Containers",
  },
  {
    path: "/news",
    icon: "emojione-monotone:newspaper",
    display: "News",
  },
  {
    path: "/users",
    icon: "fa-solid:users",
    display: "Users",
  },
];

export default navLinks;
