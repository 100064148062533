// ** React Import
import { useState } from 'react'
import SidebarForm from '../../components/SidebarForm'
import { useForm, Controller } from 'react-hook-form'
import { Button, Label, FormText, Form, Input } from 'reactstrap'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';


const defaultValues = {
  userName: '',
  email: '',
  role:'Author',
  password1:'',
  password2:''
}

const schema = yup.object({
  userName: yup.string().required(),
  email: yup.string().required(),
  role: yup.string().required(),
  password1: yup.string().min(5).max(20).required(),
  password2: yup.string().oneOf([yup.ref('password1'),null],"passwords don't matchs").required(),
})

const SidebarNewUsers = ({ open, toggleSidebar }) => {
  const [role, setRole] = useState('Author')

  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const navigate = useNavigate()

  const {
    control,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  });

  // ** Function to handle form submit
  const onSubmit = async data => {
    const formData = new FormData()
    formData.append('userName',data.userName)
    formData.append('email',data.email)
    formData.append('password',data.password1)
    formData.append('superUser',data.role === 'Author' ? false : true)
      toggleSidebar()
        // add user
      try {
        await axios.post('/users',formData,{headers:{
          "Authorization": `Bearer ${userData?.token}`
        }})
        window.location.reload()
      } catch (error) {
        if(error.response.data.message === 'jwt expired'){
          navigate('/login')
          window.localStorage.removeItem('userData')
        }
      }
  }

  const handleSidebarClosed = () => {
    for (const key in defaultValues) {
      setValue(key, '')
    }
    setRole('Author')
    setValue('role','Author')
    clearErrors(['userName','email','password1','password2','role'])
  }

  return (
    <SidebarForm
      size='lg'
      open={open}
      title='Add New User'
      headerClassName='mb-1'
      contentClassName='pt-0'
      toggleSidebar={toggleSidebar}
      onClosed={handleSidebarClosed}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-1'>
          <Label className='form-label' for='userName'>
            Username <span className='text-danger'>*</span>
          </Label>
          <Controller
            name='userName'
            control={control}
            render={({ field }) => (
              <Input id='userName' placeholder='johnDoe99' invalid={errors.userName && true}  {...field} />
            )}
          />
          <small className=' text-danger'>{errors.userName?.message}</small>
        </div>
        <div className='mb-1'>
          <Label className='form-label' for='userEmail'>
            Email <span className='text-danger'>*</span>
          </Label>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <Input
                type='email'
                id='userEmail'
                placeholder='john.doe@example.com'
                invalid={errors.email && true}
                {...field}
              />
            )}
          />
          {errors.email ? <small className=' text-danger'>{errors.email?.message}</small> : <FormText color='muted'>You can use letters, numbers & periods</FormText>}
        </div>
        <div className='mb-1'>
          <Label className='form-label' for='password1'>
            Password <span className='text-danger'>*</span>
          </Label>
          <Controller
            name='password1'
            control={control}
            render={({ field }) => (
              <Input
                type='password'
                id='password1'
                placeholder='Password'
                invalid={errors.password1 && true}
                {...field}
              />
            )}
          />
          <small className=' text-danger'>{errors.password1?.message}</small>
        </div>
        <div className='mb-1'>
          <Label className='form-label' for='password2'>
            Password <span className='text-danger'>*</span>
          </Label>
          <Controller
            name='password2'
            control={control}
            render={({ field }) => (
              <Input
                type='password'
                id='password2'
                placeholder='Password'
                invalid={errors.password2 && true}
                {...field}
              />
            )}
          />
          <small className=' text-danger'>{errors.password2?.message}</small>
        </div>
        <div className='mb-1'>
          <Label className='form-label' for='role'>
            User Role
          </Label>
          <Controller
            name='role'
            control={control}
            render={({ field }) => (
              <Input type='select' id='role' value={role} invalid={errors.role && true}  {...field} >
                <option value='Author'>Author</option>
                <option value='Admin'>Admin</option>
              </Input>
            )}
          />
        </div>

        <div className='mt-4'>
        <Button type='submit' className='me-1' color='primary'>
          Submit
        </Button>
        <Button type='reset' color='secondary' outline onClick={toggleSidebar}>
          Cancel
        </Button>
        </div>
      </Form>
    </SidebarForm>
  )
}

export default SidebarNewUsers
