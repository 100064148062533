import React from 'react'
// import Avatar from '@components/avatar'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from 'reactstrap'
import { Image } from 'react-bootstrap'
import { MoreVertical, Trash2 } from 'react-feather'
import { Icon } from '@iconify/react';
// import { Link } from 'react-router-dom'
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';

function ReportsTable({ data }) {
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  const navigate = useNavigate()
  return (
    <Table className='table table-borderless'>
      <thead>
        <tr>
          <th className='th-id'>#</th>
          <th className='text-center th-image'>Image</th>
          <th className='text-end'>Content</th>
          <th className='text-end th-email'>Email</th>
          <th className='text-center'>Date</th>
          <th className='text-center'>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((fetchedReport, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              {/* TODO: make it dynamic from db using fetchedReport.image */}
              <td> <Image src={require(`../../assets/images/car-01.png`)} /></td>
              <td className='text-muted col-4'>
                <div className='row'>
                  <div dir='rtl'>{fetchedReport.content}</div>
                </div>
              </td>

              <td className='text-end' dir='rtl'>{fetchedReport.email}</td>

              <td className='text-muted text-center'>{fetchedReport.date}</td>
              <td className='text-center'>{fetchedReport.status === false ? (
                <span className='text-light bg-warning rounded-pill p-2 fw-bold'>Pending</span>
              ) : (
                <span className='text-light bg-success rounded-pill p-2 fw-bold'>Resolved</span>
              )}
              </td>
              <td>
                <div className='column-action'>
                  <UncontrolledDropdown>
                    <DropdownToggle tag='div' className='btn btn-sm'>
                      <MoreVertical size={20} className='cursor-pointer' />
                    </DropdownToggle>
                    <DropdownMenu>
                      { !fetchedReport.status && 
                      (
                        <DropdownItem
                        tag='a'
                        href='/'
                        className='w-100'
                        onClick={async (e) => {
                          e.preventDefault()
                          // Resolve report
                          const data = {
                            resolved: !fetchedReport.status
                          }
                          
                          try {
                            await axios.patch(`/reports/resolve/${fetchedReport.reportId}`, data, {
                              headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${userData?.token}`
                              }
                            })
                            window.location.reload()
                          } catch (error) {
         
                            if (error.response.data.message === 'jwt expired') {
                              navigate('/login')
                              window.localStorage.removeItem('userData')
                            }
                          }
                        }}
                      >
                        <Icon className='text-success' icon="ic:outline-download-done" />
                        <span id={fetchedReport.reportId} className='align-middle text-success ms-1'>Resolve</span>
                      </DropdownItem>
                      )}
                      <DropdownItem
                        tag='a'
                        href='/'
                        className='w-100'
                        onClick={async (e) => {
                          e.preventDefault()
                          // delete user
                          try {
                            await axios.delete(`/reports/${fetchedReport.reportId}`, {
                              headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${userData?.token}`
                              }
                            })
                            window.location.reload()
                          } catch (error) {
               
                            if (error.response.data.message === 'jwt expired') {
                              navigate('/login')
                              window.localStorage.removeItem('userData')
                            }
                          }
                        }}
                      >
                        <Trash2 className='text-danger me-50' size={14} />
                        <span className='align-middle text-danger ms-1'>Delete</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </td>
            </tr>
          )
        })}


      </tbody>
    </Table>
  )
}

export default ReportsTable