import axios from 'axios';
const BASE_URL = 'https://ps-node-prod.psct.gov.ly';
// const BASE_URL = 'https://ps-dev.techbeam.ly';
// const BASE_URL = 'http://localhost:9090';


export default axios.create({
    baseURL: BASE_URL
});

// export const axiosPrivate = axios.create({
//     baseURL: BASE_URL,
//     headers: { 'Content-Type': 'application/json',
    
//     }
// });